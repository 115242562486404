import { initPlasmicLoader } from "@plasmicapp/loader-nextjs";
import PriceSlider from "./components/PriceSlider/PriceSlider";
import ZipCode from "./components/ZipCode/ZipCode";
import LatestArticles from "./components/LatestArticle/LatestArticles";
import Trustpilot from "./components/Truspilot/Trustpilot";

export const PLASMIC = initPlasmicLoader({
	projects: [
		{
			id: process.env.PLASMIC_PROJECT_ID,
			token: process.env.PLASMIC_API_TOKEN,
			version:
				process.env.PLASMIC_ENV === "production"
					? "prod"
					: process.env.PLASMIC_ENV === "staging"
					? "staging"
					: undefined,
		},
	],
	// PUT TO FALSE IN PRODUCTION TO AVOID CHANGES TO BE DONE EVEN WHEN IT'S UNPUBLISHED
	preview:
		process.env.PLASMIC_ENV === "production"
			? false
			: process.env.PLASMIC_ENV === "staging"
			? false
			: true,
});

PLASMIC.registerComponent(PriceSlider, {
	name: "PriceSlider",
	displayName: "Price Slider",
	props: {
		backgroundColor: "cssColor",
	},
	defaultStyles: {
		width: "100%",
	},
});

PLASMIC.registerComponent(ZipCode, {
	name: "ZipCode",
	displayName: "ZipCode Input",
	props: {},
});

PLASMIC.registerComponent(LatestArticles, {
	name: "LatestArticles",
	displayName: "Latest Articles",
	props: {},
	defaultStyles: {
		width: "100%",
	},
});

PLASMIC.registerComponent(Trustpilot, {
	name: "Trustpilot",
	displayName: "Trustpilot",
	props: {},
	defaultStyles: {
		width: "100%",
	},
});
